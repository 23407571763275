const thumbnailTypes = [
  'maxresdefault',
  'mqdefault',
  'hqdefault',
  'default',
] as const

type YoutubeThumbnailType = typeof thumbnailTypes[number]

export function useYoutubeThumbnail(videoId?: string, initialType: YoutubeThumbnailType = 'maxresdefault') {
  const _currentType = ref(thumbnailTypes.indexOf(initialType))
  const thumbnailSrc = computed(() => {
    if (!videoId) { return '' }
    const type = thumbnailTypes.at(_currentType.value)!

    return `https://i.ytimg.com/vi/${videoId}/${type}.jpg`
  })

  const handleLoad = (e: Event) => {
    const image = e.target as HTMLImageElement
    if (image.complete && check404(image)) { _currentType.value += 1 }
  }

  // We check 404 error based on the image returned from youtube.
  const check404 = (image: HTMLImageElement) => (image.naturalHeight <= 90 && (_currentType.value < thumbnailTypes.length - 1))

  return {
    handleThumbnailLoad: handleLoad,
    thumbnailSrc,
  }
}
